.userInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;

  .userAvatar {
    width: 40px;
    height: 40px;
    border-radius: 200px;
    overflow: hidden;
    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .textInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .userNick {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 500;
      font-size: 15px;
      color: var(--white);
    }
    .time {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 10px;
      color: #d1d1d6;
    }
  }
}
