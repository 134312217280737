.loader{
  width: 40px;
  height: 40px;
  animation: loaderRotate 0.8s infinite linear;
}

@keyframes loaderRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
