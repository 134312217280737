.category {
  padding: 6px 12px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 14px;
  background:
    linear-gradient(#282631 0%, #282631 100%) padding-box,
    linear-gradient(90deg, #9e5992 0%, #6a19ab 50.52%, #3054bb 100%) border-box;
  border: 1px solid transparent;
  &.filled {
    background: linear-gradient(
      90deg,
      #9e5992 0%,
      #6a19ab 50.52%,
      #3054bb 100%
    );
    border: none;
  }
}
