.header {
  position: fixed;
  display: none;
  top: 0px;
  left: 0;
  right: 0;
  padding: 12px 14px;
  background-color: #13111c;

  @media (max-width: 1240px) {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
  }

  .arrowWrapper {
    width: max-content;
    height: max-content;
    rotate: -180deg;
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }
}
