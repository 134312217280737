.container {
  display: flex;
  padding: 14px;
  gap: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background: #191724;
  border-radius: 24px;

  @media (max-width: 1240px) {
    flex-direction: column;
  }

  .imageWrapper {
    min-width: 256px;
    max-width: 256px;
    aspect-ratio: 1;
    border-radius: 24px;
    overflow: hidden;

    @media (max-width: 1240px) {
      max-width: 100%;
      width: 100%;
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;

    .post {
      display: flex;
      flex-direction: column;
      gap: 6px;
      flex-grow: 1;

      .postPrompt {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #d1d1d6;
      }
      .postTeg {
        display: flex;
        gap: 10px;
      }
    }

    .cardFooter {
      display: flex;
      justify-content: space-between;
      margin-top: auto;

      @media (max-width: 1240px) {
        flex-direction: column;
        gap: 6px;
      }

      .buttons {
        display: flex;
        gap: 16px;

        @media (max-width: 1240px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          order: 2;

          &.skip {
            grid-template-columns: 1fr;
          }
        }

        .button {
          padding: 10px 20px;

          @media (max-width: 1240px) {
            width: 100%;
          }
        }
      }

      .postStatistic {
        display: flex;

        @media (max-width: 1240px) {
          order: 1;
        }

        gap: 8px;
        .statInfo {
          display: flex;
          align-items: center;
          padding: 10px;
          gap: 6px;
        }
      }
    }
  }

  @media (max-width: 1240px) {
    &.one {
      flex-direction: row;

      .imageWrapper {
        min-width: 256px;
        max-width: 256px;
        aspect-ratio: 1;
      }

      .cardFooter {
        flex-direction: row;
        gap: 0px;

        .buttons {
          display: flex;
          grid-template-columns: repeat(2, 1fr);
          order: 1;

          .button {
            width: fit-content;
          }
        }

        .postStatistic {
          order: 2;
        }
      }
    }
  }
}
