.detail {
  display: flex;
  flex-direction: column;
  background-color: #191724;
  border-radius: 12px;

  @media (max-width: 767px) {
    border-radius: 0px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;
    border-radius: 12px;
    transition: background 0.2s linear;
    overflow: hidden;

    &:hover {
      background: #2e2c37;
    }

    &.active {
      &:hover {
        background: transparent;
      }
    }
  }

  &__title {
    font-weight: 500;
    color: #b8b7ba;
  }

  &__arrow {
    rotate: 90deg;
    transition: rotate 0.3s;

    &.rotate {
      rotate: -90deg;
    }
  }

  &__statsWrapper {
    overflow: hidden;
  }

  &__stats {
    padding: 16px;
    padding-top: 0px;
    background-color: #191724;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
  }

  &__stat {
    display: flex;
    gap: 6px;
    line-height: 140%;

    span {
      text-transform: uppercase;
      color: #948aa3;
    }
  }
}
