.postComments {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;

  .comments {
    display: grid;
    gap: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    background: #191724;
    border-radius: 24px;

    & > div:last-child {
      border-bottom: none;
    }
  }

  .nothing {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 16px;
    color: #d1d1d6;
  }

  .loaderWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
