.label {
  position: relative;
  display: flex;
  align-items: center;
  height: 45px;
  padding-inline: 20px;
  color: #b0b0b8;
  background-color: #1e1c27;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  cursor: text;

  .input {
    width: 100%;
    color: inherit;
    background-color: transparent;
    border: none;
    outline: none;

    &::placeholder {
      color: inherit;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
