.form {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .socials {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 14px;

    &__button {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }

  .form__button {
    width: 100%;
    height: 50px;
  }
}
