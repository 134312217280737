.strikePosts {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1240px) {
    padding-bottom: 20px;
  }

  .title {
    display: block;
    font-size: 16px;
    line-height: 140%;
    font-weight: 600;
    color: #d1d1d6;

    @media (max-width: 1240px) {
      display: none;
    }
  }

  .posts {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 1240px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  .loaderWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
