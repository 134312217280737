.posts {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 1240px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;

  @media (max-width: 1240px) {
    margin-bottom: 20px;
  }
}
