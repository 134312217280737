.dashboardCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  border-radius: 12px;
  background: #191724;
  transition: background 0.2s linear;

  @media (max-width: 767px) {
    border-radius: 0;
  }

  &:hover {
    background: #2e2c37;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 16px;
    .imageWrapper {
      width: 24px;
      height: 24px;
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
