.slider {
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 100%;
  &Button {
    position: absolute;
    bottom: 20px;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    border-radius: 200px;
    color: #000;
    background-color: #ffffff;
  }
  &Arrow {
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    fill: currentColor;
    color: #4628ff;
    &Left {
      left: 30%;
    }
    &Right {
      left: 70%;
    }
  }
  &Rail {
    width: 2px;
    height: 100%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    background-color: #ffffff;
  }
}
