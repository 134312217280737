.aside {
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  grid-area: aside;
  height: max-content;
  padding: 0 20px;

  .logo {
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 70px;
    height: auto;
    padding: 19px 14px;

    &__image {
      display: block;
      width: 48px;
      height: auto;
      object-fit: contain;
    }

    &__text {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .goBack {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 14px;
    border-radius: 200px;
    background: #191724;
    transition: background 0.2s linear;

    &:hover {
      background: #2e2c37;
    }
  }

  .nav {
    &__title {
      display: block;
      margin-bottom: 14px;
      font-weight: 600;
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 14px;
      width: 100%;
      list-style: none;

      &__item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 14px;
        border-radius: 200px;
        background: #191724;
        transition: background 0.2s linear;

        &:hover {
          background: #2e2c37;
        }

        &.active {
          background: linear-gradient(
            90deg,
            #9e5992 0%,
            #6a19ab 50.52%,
            #3054bb 100%
          );
        }
      }
    }
  }
}
