.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px 15px 30px 15px;
  backdrop-filter: blur(30px);
  background: rgba(19, 17, 28, 0.8);
  border-top: 1px solid #191724;
  border-radius: 20px 20px 0 0;
  z-index: 9;

  .tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    height: 40px;
    color: #948aa3;

    &__text {
      font-weight: 500;
      font-size: 10px;
    }
  }
}
