.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 460px;
    width: 100%;
    padding: 40px;
    background-color: #13111c;
    border: 2px solid #1f1d28;
    border-radius: 24px;

    .tabs {
      display: flex;
      gap: 10px;
    }
  }
}
