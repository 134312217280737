.strike {
  .list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__item {
      display: flex;
      justify-content: space-between;
      padding: 14px;
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
      background: #191724;
      border-radius: 12px;

      @media (max-width: 767px) {
        border-radius: 0;
      }
    }

    &__text {
      line-height: 140%;
      color: #d1d1d6;
    }
  }
}
