.confirm {
  display: grid;
  gap: 30px;
  max-width: 460px;
  width: 100%;
  padding: 40px;
  background: #13111c;
  border: 2px solid #1f1d28;
  border-radius: 24px;

  @media (max-width: 767px) {
    max-width: 100%;
    border-radius: 0px;
    border: none;
    border-top: 2px solid #1f1d28;
  }

  .title {
    font-weight: 500;
  }

  .actions {
    display: flex;
    gap: 10px;

    .button {
      width: 90px;
    }
  }
}
