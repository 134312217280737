.outerWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.innerWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.imageContainer {
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform-origin: 0 0;
}

.hidden {
  visibility: hidden;
  width: 100%;
  height: auto;
}
