.layout {
  display: grid;
  grid-template-areas: 'aside header' 'aside main';
  grid-template-columns: 290px 1fr;
  grid-template-rows: 70px 1fr;
  column-gap: 40px;
  row-gap: 30px;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  padding: 30px 32px 0;
  margin: 0 auto;

  @media (max-width: 1240px) {
    grid-template-areas: 'main';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 0;
  }

  .main {
    grid-area: main;
    padding: 0px 20px 20px;

    @media (max-width: 1240px) {
      padding: 67px 20px 82px;
    }

    @media (max-width: 767px) {
      padding: 67px 0px 82px;
    }
  }
}
