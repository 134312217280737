.container {
  display: flex;
  padding: 14px;
  gap: 16px;
  border-bottom: 1px solid #282631;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  .imageWrapper {
    min-width: 256px;
    max-width: 256px;
    aspect-ratio: 1;
    border-radius: 24px;
    overflow: hidden;

    @media (max-width: 600px) {
      max-width: 100%;
      width: 100%;
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;

    .header {
      display: flex;
      gap: 20px;
      align-items: center;

      &Card {
        display: flex;
        gap: 10px;
        align-items: center;

        & > img {
          width: 40px;
          height: 40px;
        }

        & > p {
          max-width: 250px;
          width: 100%;
          font-size: 10px;
          line-height: 140%;
          color: #d1d1d6;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }

    .post {
      display: flex;
      flex-direction: column;
      gap: 6px;
      flex-grow: 1;

      .postPrompt {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #d1d1d6;
      }
      .postTeg {
        display: flex;
        gap: 10px;
      }
    }

    .cardFooter {
      display: flex;
      justify-content: space-between;
      margin-top: auto;

      @media (max-width: 600px) {
        flex-direction: column;
        gap: 6px;
      }

      .buttons {
        display: flex;
        gap: 16px;

        @media (max-width: 600px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          order: 2;
        }

        .button {
          padding: 10px 20px;

          @media (max-width: 600px) {
            width: 100%;
          }
        }
      }

      .postStatistic {
        display: flex;
        gap: 8px;

        @media (max-width: 600px) {
          order: 1;
        }

        .statInfo {
          display: flex;
          align-items: center;
          padding: 10px;
          gap: 6px;
        }
      }
    }
  }

  .loaderWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
