.button {
  width: max-content;
  height: 40px;
  padding-inline: 12px;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  background: #1e1c27;
  border-radius: 200px;
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.1);
  transition: background 0.2s linear;

  &.filled {
    background: linear-gradient(
      90deg,
      #9e5992 0%,
      #6a19ab 50.52%,
      #3054bb 100%
    );
  }
  &.green {
    background: #1a9a55;

    &:hover {
      background: #1a593c;
    }
  }
  &.red {
    background: #a1343f;

    &:hover {
      background: #5e2631;
    }
  }
}
