.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #d1d1d6;
    @media (max-width: 1240px) {
      display: none;
    }
  }
}
