@import 'typography.scss';
@import 'toastify.scss';

:root {
  --main-color: #ffffff;
  --background-color: #13111c;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 16px;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: var(--main-color);
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  min-width: 100vw;
}

html,
body {
  ::-webkit-scrollbar {
    width: 6px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(19, 17, 28, 0.2);
  }

  ::-webkit-scrollbar-thumb {
    background: linear-gradient(
      to top,
      rgba(196, 0, 214, 0.2),
      rgba(70, 40, 255, 0.2)
    );
    border-radius: 200px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  user-select: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  background-color: transparent;
  border: none;
}

ul {
  list-style: none;
}

#root {
  width: inherit;
  height: inherit;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.modal {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(30px);
  background: rgba(19, 17, 28, 0.95);
  z-index: 10;

  @media (max-width: 767px) {
    align-items: flex-end;
  }
}

.Toastify__progress-bar--error {
  background: linear-gradient(
    115.93deg,
    #9e5959 3.98%,
    #ab1919 58.05%,
    #bb3030 112.12%
  ) !important;
}
.Toastify__progress-bar--success {
  background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%) !important;
}
