.user {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }

  &__avatarWrapper {
    min-width: 120px;
    max-width: 120px;
    aspect-ratio: 1;
    border-radius: 200px;
    overflow: hidden;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .info {
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    gap: 8px;
    flex: 1;
    height: inherit;

    @media (max-width: 767px) {
      text-align: center;
    }

    &__nickname {
      display: flex;
      gap: 8px;
      font-weight: 500;
      font-size: 20px;

      @media (max-width: 767px) {
        justify-content: center;
      }
    }

    &__desc {
      flex-grow: 1;
      line-height: 140%;
    }

    &__button {
      padding: 0px 20px;
      background: #2e2c37;

      &:hover {
        background: #1e1c27;
      }
    }
  }

  .stats {
    display: flex;
    align-self: flex-end;
    gap: 24px;
    padding: 20px;

    @media (max-width: 767px) {
      align-self: auto;
    }

    .stat {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 5px;

      &__value {
        font-weight: 600;
        font-size: 24px;
      }

      &__text {
        font-weight: 500;
        font-size: 14px;
        color: #d1d1d6;
      }
    }
  }
}
