.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  .subscription {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 16px;
    background: #191724;
    border-radius: 12px;
    box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.1);

    @media (max-width: 767px) {
      flex-direction: column;
      padding: 16px;
      border-radius: 0px;
    }

    &__text {
      display: flex;
      align-items: center;
      gap: 15px;
      font-weight: 500;
      color: #b8b7ba;
    }

    &__expired {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
    }

    &__button {
      height: 30px;
      padding-inline: 20px;
    }
  }

  .detail {
    display: flex;
    flex-direction: column;
    background-color: #191724;
    border-radius: 12px;

    @media (max-width: 767px) {
      border-radius: 0px;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      cursor: pointer;
      border-radius: 12px;
      transition: background 0.2s linear;
      overflow: hidden;

      &:hover {
        background: #2e2c37;
      }

      &.active {
        &:hover {
          background: transparent;
        }
      }
    }

    &__title {
      font-weight: 500;
      color: #b8b7ba;
    }

    &__arrow {
      rotate: 90deg;
      transition: rotate 0.3s;

      &.rotate {
        rotate: -90deg;
      }
    }

    &__statsWrapper {
      overflow: hidden;
    }

    &__stats {
      padding: 16px;
      padding-top: 0px;
      background-color: #191724;
      border-radius: 0 0 12px 12px;
      overflow: hidden;
    }

    &__stat {
      display: flex;
      gap: 6px;
      line-height: 140%;

      span {
        text-transform: uppercase;
        color: #948aa3;
      }
    }
  }
}
