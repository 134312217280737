.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 102;

  .iconButton {
    &:disabled {
      opacity: 0.5;
    }
  }

  .iconSetting {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 35px;
    border: none;
    opacity: 0.7;
    cursor: pointer;
    transition: 0.3s;

    &.active {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }
}
