.header {
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  grid-area: header;
  padding: 0 20px;
  background-color: #13111c;
  z-index: 9;

  .user {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 200px;
    transition: background 0.2s linear;

    &:hover {
      background: #2e2c37;
    }

    &__image {
      width: 40px;
      height: 40px;
      border-radius: 200px;
      object-fit: cover;
      object-position: top;
      border: 2px solid #282535;
    }
  }
}
