.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.85);
  transition: opacity 0.5s ease;
  opacity: 0;
  z-index: 10;

  &.active {
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  .body {
    position: relative;
    max-width: 100%;
    width: 100%;
    height: 100%;

    .imageWrapper {
      width: 100%;
      height: 100%;
    }

    .image {
      object-fit: contain;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }

      &.isWidth {
        width: 100%;
        max-width: 100%;
      }

      &.isHeight {
        height: 100%;
        max-height: 100%;
      }

      &.isWidthIsHeight {
        width: max-content;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
      }

      &.isHeightIsWidth {
        width: 100%;
        max-width: 100%;
        height: max-content;
        max-height: 100%;
      }
    }

    .imageCompareWrapper {
      width: 100%;
      height: 100%;
    }

    .resetButton {
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: #ffffff;
      transition:
        background-color 0.3s ease,
        opacity 0.3s ease;

      &:disabled {
        opacity: 0;
        pointer-events: none;
      }
    }

    .prevImageWrapper,
    .nextImageWrapper {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;

      .image {
        width: 100%;
        height: 100%;
      }
    }

    .prevImageWrapper {
      right: 150%;
    }
    .nextImageWrapper {
      left: 100%;
    }
  }

  .prevArrow,
  .nextArrow {
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 30px;
    z-index: 1;

    @media (max-width: 767px) {
      padding: 20px 10px;
    }
  }

  .prevArrow {
    left: 0;
    rotate: 270deg;
    transform: translateX(50%);
  }
  .nextArrow {
    right: 0;
    rotate: 90deg;
    transform: translateX(-50%);
  }

  .loaderWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
