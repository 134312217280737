.container {
  display: grid;
  grid-template-areas: 'info . buttons stat';
  grid-template-columns: max-content 1fr max-content max-content;
  gap: 16px;
  padding: 14px;
  background: #191724;
  border-radius: 24px;
  cursor: pointer;

  @media (max-width: 767px) {
    border-radius: 0;
  }

  @media (max-width: 500px) {
    grid-template-areas: 'info stat' 'buttons buttons';
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }

  .buttons {
    grid-area: buttons;
    display: flex;
    gap: 16px;
    .button {
      padding: 10px 20px;

      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }

  .statInfo {
    grid-area: stat;
    display: flex;
    align-items: center;
    gap: 6px;

    @media (max-width: 500px) {
      justify-self: flex-end;
    }
  }
}
